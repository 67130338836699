import ApiService from './ApiService'
import { API_BASE_URL } from "redux/constants";

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/sign-in',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

// export async function apiForgotPassword (data) {
//     return ApiService.fetchData({
//         url: '/forgot-password',
//         method: 'post',
//         data
//     })
// }
export async function apiForgotPassword(email) {
   
    const response = await fetch(API_BASE_URL + `Merchant/ForgetPassword?EmailAddress=${email.email}`,
      {
        method: "Get",
        headers: {
        //   "key": userFulInfo.id,
        //   "orgId": userFulInfo.organizationId
        }
      })
    const data = await response.json();
    
    return data;
  }
export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
